<template>
  <v-app>
    <div>
      <v-alert
      v-model="alert"
      border="left"
      dark
      dismissible
      class="alert"
      :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
    >
    {{alertMessage}}
        </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <div class="mb-8 mt-8 text-center">
          <h1>Add Salary Component</h1>
        </div>
        <v-form ref="salarycomponent" v-model="valid">
          <v-row no-gutters>
            <v-col >
              <TextField v-model="formData.description" :label="'Name'" />
            </v-col>
            <v-col >
              <SelectField
                :items="frequency"
                v-model="formData.SalaryComponentFrequencyId"
                :label="'Frequency'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col>
              <SelectField
                :items="salaryComponentType"
                v-model="formData.salaryComponentType"
                :label="'Salary Component Type'"
                :itemText="'name'"
                :itemValue ="'id'"
                @change="salaryComponentChange"

              />
            </v-col>
            <v-col v-if="formData.salaryComponentType === 4">
              <SelectField
                :items="StatoryDeductionType"
                v-model="formData.statoryDeductionType"
                :label="'Statory Deducation Type'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            <v-col >
              <TextField v-model="formData.rate" :type="'number'" :label="'Rate'" />
            </v-col>
            </v-col>
            <v-col>
              <SelectField
                :items="months"
                v-model="formData.EffectiveMonth"
                :label="'Effective Month'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col >
              <TextField v-model="formData.markup" :type="'number'" :validate="false"
               :label="'Markup'" />
            </v-col>
            <v-col dense v-if="formData.salaryComponentType === 2">
              <v-radio-group v-model="formData.isVacationAllowance" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Vacation allowance ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" v-if="formData.isVacationAllowance === true">
              <SelectField
                :items="VacationAllowanceType"
                v-model="formData.vacationAllowanceType"
                :label="'Vacation Allowance Type'"
                :itemText="'name'"
                :itemValue ="'id'"
                :validate="false"

              />
            </v-col>
            <v-col dense>
              <v-radio-group v-model="formData.isTimeSheetDriven" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Timesheet ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col dense v-if="formData.salaryComponentType === 3">
              <v-radio-group v-model="formData.DependsOnYearsOfService" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Depends on years of service ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>

              <v-col cols="6" v-if="formData.isTimeSheetDriven === true">
              <SelectField
                :items="TimesheetItemType"
                v-model="formData.TimesheetItemType"
                :label="'Timesheet Item type'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="6" v-if="formData.TimesheetItemType ===  1 ">
              <SelectField
                :items="OverTimeType"
                v-model="formData.OverTimeType"
                :label="'Overtime Type'"
                :itemText="'name'"
                :itemValue ="'id'"

              />
            </v-col>

            <v-col>
              <v-radio-group v-if="formData.isAllowance === false" v-model="formData.isBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="CMS ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isFlat" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label="Fixed Amount ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isBaseOnBasic" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Based on CMS ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
              <v-radio-group v-model="formData.isTaxable" :mandatory="false" class="radiogroup" :column="false" :dense="true" :validate-on-blur="true" label=" Taxable ?">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="d-flex justify-end">

                      <Button
                      :label="'Submit'"
                      :btnType="'Submit'"
                      @onClick="dialog= true"
                      :disabled="!valid"
                      :isLoading="isSavingUser"
                      class="mr-4"
                    />

                    <Button :label="'Reset'" :btnType="'Cancel'" @onClick="onReset" />

                  </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
  </v-app>
</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import SelectField from '@/components/ui/form/SelectField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_SALARY } from '@/store/action-type'
export default {
  components: {
    TextField,
    SelectField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      alertMessage: '',
      alert: false,
      alertType: '',
      valid: false,
      isSavingUser: false,
      formData: {
        description: '',
        EffectiveMonth: 0,
        rate: 0,
        isVacationAllowance: false,
        isTimeSheetDriven: null,
        isFlat: null,
        TimesheetItemType: 0,
        OverTimeType: 0,
        isBaseOnBasic: null,
        DependsOnYearsOfService: false,
        SalaryComponentFrequencyId: '',
        isTaxable: null,
        markup: 0,
        salaryComponentType: '',
        vacationAllowanceType: 0,
        statoryDeductionType: 0
      },
      salaryComponentType: [{ id: 1, name: 'ConsolidatedSalary' }, { id: 2, name: 'Allowance' }, { id: 3, name: 'Benefit' }, { id: 4, name: 'Statutory' }, { id: 5, name: 'Deduction' }],
      frequency: [{ id: 1, name: 'Daily' }, { id: 2, name: 'weekly' }, { id: 3, name: 'Monthly' }, { id: 4, name: 'Yearly' }, { id: 5, name: 'One Time' }],
      TimesheetItemType: [{ id: 1, name: 'Overtime' }, { id: 2, name: 'Shift' }],
      OverTimeType: [{ id: 1, name: 'On Duty' }, { id: 2, name: 'Off Duty' }],
      months: [{ id: '0', name: 'None' }, { id: 1, name: 'January' }, { id: 2, name: 'February' }, { id: 3, name: 'March' }, { id: 4, name: 'April' }, { id: 5, name: 'May' }, { id: 6, name: 'June' }, { id: 7, name: 'July' }, { id: 8, name: 'August' }, { id: 9, name: 'September' }, { id: 10, name: 'October' }, { id: 11, name: 'November' }, { id: 12, name: 'December' }],
      VacationAllowanceType: [
        { id: '0', name: 'None' },
        { id: 1, name: 'Vacation Allowance' },
        { id: 2, name: 'Vacation Not Taken' }
      ],
      StatoryDeductionType: [
        { id: '0', name: 'None' },
        { id: 1, name: 'ITF' },
        { id: 2, name: 'NSITF' },
        { id: 3, name: 'Employee Pension' },
        { id: 4, name: 'Employer Pension' }
      ]

    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
      setTimeout(() => { this.alert = false }, 5000)
    },
    salaryComponentChange (item) {
      console.log(item)
    },
    onSave () {
      this.isSavingUser = true
      console.log(this.formData)
      const data = {
        'description': this.formData.description,
        'isTimeSheetDriven': this.formData.isTimeSheetDriven,
        'isFlat': this.formData.isFlat,
        'isBasedOnBasic': this.formData.isBaseOnBasic,
        'salaryComponentFrequencyId': this.formData.SalaryComponentFrequencyId,
        'isTaxable': this.formData.isTaxable,
        'markup': this.formData.markup,
        'IsVacationAllowance': this.formData.isVacationAllowance,
        'Rate': this.formData.rate,
        'salaryComponentType': this.formData.salaryComponentType,
        'TimesheetItemType': this.formData.TimesheetItemType,
        'OvertimeType': this.formData.OverTimeType,
        'EffectiveMonth': this.formData.EffectiveMonth,
        'StatoryDeductionType': this.formData.statoryDeductionType,
        'VacationAllowanceType': this.formData.vacationAllowanceType,
        'DependsOnYearsOfService': this.formData.salaryComponentType === 3 ? this.formData.DependsOnYearsOfService : false
      }

      console.log(data)
      this.$store.dispatch(CREATE_SALARY, data).then(() => {
        this.showAlertMessage('Salary component creation successful', 'success')
      }).catch((error) => {
        console.log(error)
        this.isSavingUser = false
        this.showAlertMessage('Salary component creation failed', 'error')
      }).finally(() => {
        this.isSavingUser = false
        this.dialog = false
        this.onReset()
      })
    },
    onReset () {
      this.$refs.salarycomponent.reset()
    }
  }
}
</script>
<style scoped>

.radiogroup{
  width: 50%;
  align-items: flex-start !important;
}
.row .col{
  box-shadow: none !important;
  padding: 0 !important;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
